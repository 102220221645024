<template>
  <div class="layout">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    langs: {},
    admin: false,
    authorized: false,
    clearcacheurl: "",
    siteid: "",
    fuserid: ""
  },
  created() {
    this.$store.commit('langsState', this.langs);
    this.$store.commit('adminState', this.admin);
    this.$store.commit('authorizedState', this.authorized);
    this.$store.commit('clearcacheurlState', this.clearcacheurl);
    this.$store.commit('siteid', this.siteid);
    this.$store.commit('fuserid', this.fuserid);
  }
}
</script>

<style lang="scss" scoped>
.layout {
}
</style>
